import axios from 'axios';

import { RootState } from '@/store';
import { ActionContext } from 'vuex';

export type UserType = {
  account: {
    id?: number,
    email?: string,
    creationDate?: Date,
    lastLogin?: Date,
    updateDate?: Date,
    role?: any,
    accountCreatorId?: number,
    accountCreatorName?: string,
    accountCreatorEmail?: string,
    customerId?: number,
    customerAcronym?: string,
  },
};

export type AuthStateType = {
  isSignInPageVisible: boolean,
  user: UserType,
};

export default {
  namespaced: true,
  state: (): AuthStateType => ({
    isSignInPageVisible: false,
    user: {
      account: {
        id: undefined,
        email: undefined,
        creationDate: undefined,
        lastLogin: undefined,
        updateDate: undefined,
        role: undefined,
        accountCreatorId: undefined,
        accountCreatorName: undefined,
        accountCreatorEmail: undefined,
        customerId: undefined,
      },
    },
  }),
  getters: {
    getUser: function (state: AuthStateType): UserType {
      return state.user;
    },
    getSignInPageVisibility: function (state: AuthStateType): boolean {
      return state.isSignInPageVisible;
    },
  },
  mutations: {
    setUser: function (state: AuthStateType, value: UserType): void {
      state.user = value;
    },
    setSignInPageVisibility: function (state: AuthStateType, value: boolean): void {
      state.isSignInPageVisible = value;
    },
  },
  actions: {
    getLoggedUser: async function ({ commit }: ActionContext<AuthStateType, RootState>): Promise<any> {
      try {
        const { data } = await axios({
          url: '/account-logged-user',
          baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
          method: 'GET',
          responseType: 'json',
          withCredentials: true,
        });

        if (data?.account) {
          commit('setUser', {
            account: {
              ...data.account,
            },
          });
        } else {
          commit('setUser', {
            account: {
              id: undefined,
              email: undefined,
              creationDate: undefined,
              lastLogin: undefined,
              updateDate: undefined,
              role: undefined,
              accountCreatorId: undefined,
              accountCreatorName: undefined,
              accountCreatorEmail: undefined,
              customerId: undefined,
            },
          });
        }
      } catch (e) {
        console.log(e); /* eslint-disable-line no-console */
      }
    },
    signOut: async function ({ commit }: ActionContext<AuthStateType, RootState>): Promise<any> {
      await axios({
        url: '/account-sign-out',
        baseURL: process.env.VUE_APP_REQUEST_BASE_URL,
        method: 'POST',
        responseType: 'json',
        withCredentials: true,
      });

      commit('setUser', {
        account: {
          id: undefined,
          email: undefined,
          creationDate: undefined,
          lastLogin: undefined,
          updateDate: undefined,
          role: undefined,
          accountCreatorId: undefined,
          accountCreatorName: undefined,
          accountCreatorEmail: undefined,
          customerId: undefined,
        },
      });
    },
    toggleSignInPageVisibility: function ({ commit, getters }: ActionContext<AuthStateType, RootState>, payload: { value: boolean, }): void {
      commit('setSignInPageVisibility', payload?.value !== undefined ? payload.value : !getters.getSignInPageVisibility);
    },
  },
};
